import { gql } from 'apollo-boost';

export const GET_CLIENT_PARTICIPANT_DATA = gql`
  query getClientParticipantData($id: ID!) {
    client(id: $id) {
      id
      participantSet {
        edges {
          node {
            wheel {
              raising {
                id
                number
                fund {
                  id
                  businessName
                  fundType
                }
              }
            }
          }
        }
      }
    }
  }
`;

// Client holder information

export const GET_CLIENT_HOLDER_INFORMATION = gql`
  query getClientHolderInformation($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        firstName
        lastName
        maternalSurname
        documentNumber
        documentType {
          id
          name
        }
      }
      alternativeDocumentType
      holderParticipations {
        edges {
          node {
            id
            businessName
            ruc
            participationPercentage
          }
        }
      }
      holderPublicFunctions {
        edges {
          node {
            id
            entity
            lastRole
            timeRole
          }
        }
      }
    }
  }
`;

// Client participation

export const CREATE_CLIENT_PARTICIPATION = gql`
  mutation createClientParticipation($input: ClientParticipationInput!) {
    createClientParticipation(input: $input) {
      participation {
        id
        businessName
        ruc
        participationPercentage
      }
    }
  }
`;

export const UPDATE_CLIENT_PARTICIPATION = gql`
  mutation updateClientParticipation(
    $id: ID!
    $input: ClientParticipationInput!
  ) {
    updateClientParticipation(id: $id, input: $input) {
      participation {
        id
        businessName
        ruc
        participationPercentage
      }
    }
  }
`;

export const DELETE_CLIENT_PARTICIPATION = gql`
  mutation deleteClientParticipation($id: ID!) {
    deleteClientParticipation(id: $id) {
      participation {
        id
      }
    }
  }
`;

// Client public functions

export const CREATE_CLIENT_PUBLIC_FUNCTIONS = gql`
  mutation createClientPublicFunction($input: ClientPublicFunctionInput!) {
    createClientPublicFunction(input: $input) {
      publicFunction {
        id
        entity
        lastRole
        timeRole
      }
    }
  }
`;

export const UPDATE_CLIENT_PUBLIC_FUNCTIONS = gql`
  mutation updateClientPublicFunction(
    $id: ID!
    $input: ClientPublicFunctionInput!
  ) {
    updateClientPublicFunction(id: $id, input: $input) {
      publicFunction {
        id
        entity
        lastRole
        timeRole
      }
    }
  }
`;

export const DELETE_CLIENT_PUBLIC_FUNCTIONS = gql`
  mutation deleteClientPublicFunction($id: ID!) {
    deleteClientPublicFunction(id: $id) {
      publicFunction {
        id
        entity
        lastRole
        timeRole
      }
    }
  }
`;

// Client Attorney Labor Information

export const GET_CLIENT_ATTORNEY_LABOR_DATA = gql`
  query getClientAttorneyLaborData($id: ID!) {
    client(id: $id) {
      id
      assignee {
        id
        occupation
      }
    }
  }
`;

export const UPDATE_CLIENT_ATTORNEY_LABOR_DATA_CLIENT = gql`
  mutation updateClientAttorneyLaborData($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      client {
        id
        occupation
      }
    }
  }
`;

// Client Labor Data

export const GET_CLIENT_LABOR_DATA = gql`
  query getClientLaborData($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        username
      }
      occupation
      professionalNumber
      profession
      grossMonthlySalary
      otherGrossMonthlySalary
      otherMonthlyIncome

      hasWorkingExperience
      workplace
      role
      workingTime
      reasonToLeave
      startDate
      endDate

      workplaceSecond
      roleSecond
      workingTimeSecond
      reasonToLeaveSecond
      startDateSecond
      endDateSecond

      workplaceThird
      roleThird
      workingTimeThird
      reasonToLeaveThird
      startDateThird
      endDateThird

      averageMonthIncome {
        id
        minimum
        maximum
      }
      educationLevel {
        id
      }
      ruc
      taxNumber
    }
  }
`;

export const UPDATE_CLIENT_LABOR_DATA = gql`
  mutation updateClientLaborData($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      client {
        id
        user {
          id
          username
        }
        occupation
        professionalNumber
        profession
        grossMonthlySalary
        otherGrossMonthlySalary
        otherMonthlyIncome

        hasWorkingExperience
        workplace
        role
        workingTime
        reasonToLeave
        startDate
        endDate

        workplaceSecond
        roleSecond
        workingTimeSecond
        reasonToLeaveSecond
        startDateSecond
        endDateSecond

        workplaceThird
        roleThird
        workingTimeThird
        reasonToLeaveThird
        startDateThird
        endDateThird

        averageMonthIncome {
          id
          minimum
          maximum
        }
        educationLevel {
          id
        }
        ruc
        taxNumber
      }
      errors {
        field
        message
      }
    }
  }
`;

// Spouse Labor Information

export const GET_CLIENT_SPOUSE_LABOR_DATA = gql`
  query getClientSpouseLaborData($id: ID!) {
    client(id: $id) {
      id
      spouse {
        id
        user {
          id
          username
        }
        birthplace {
          id
          country {
            id
          }
        }
        occupation
        workplace
        role
        workingTime
        averageMonthIncome {
          id
          minimum
          maximum
        }
        educationLevel {
          id
        }
        ruc
        taxNumber
      }
    }
  }
`;

// Client assignee legal information

export const GET_CLIENT_ASSIGNEE_LEGAL_DATA = gql`
  query getClientAssigneeLegalData($id: ID!) {
    client(id: $id) {
      id
      assignee {
        id
        user {
          id
          documentType {
            id
          }
          documentNumber
        }
        alternativeDocumentType
        nationalitySet {
          edges {
            node {
              id
              nationality
            }
          }
        }
        maritalStatus {
          id
        }
      }
    }
  }
`;

export const UPDATE_CLIENT_ASSIGNEE_LEGAL_DATA = gql`
  mutation updateClientAssigneeLegalData($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      client {
        id
        user {
          id
          documentType {
            id
          }
          documentNumber
        }
        alternativeDocumentType
        domiciled
        countryAddress {
          id
        }
        usPerson
        nationalitySet {
          edges {
            node {
              id
              nationality
            }
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

// Client legal data

export const GET_CLIENT_LEGAL_DATA = gql`
  query getClientLegalData($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        documentType {
          id
        }
        documentNumber
      }
      alternativeDocumentType
      photoDocumentFront
      photoDocumentBack
      domiciled
      countryAddress {
        id
      }
      usPerson
      nationalitySet {
        edges {
          node {
            id
            nationality
          }
        }
      }
      maritalStatus {
        id
        spouse
        commonLaw
      }
      propertyRegime {
        id
        society
      }
      startDatePropertyRegime
    }
  }
`;

export const UPDATE_CLIENT_LEGAL_DATA = gql`
  mutation updateClientLegalData($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      client {
        id
        user {
          id
          documentType {
            id
          }
          documentNumber
        }
        alternativeDocumentType
        photoDocumentFront
        photoDocumentBack
        domiciled
        countryAddress {
          id
        }
        usPerson
        nationalitySet {
          edges {
            node {
              id
              nationality
            }
          }
        }
        maritalStatus {
          id
          spouse
        }
        propertyRegime {
          id
          society
        }
        startDatePropertyRegime
      }
      errors {
        field
        message
      }
    }
  }
`;

// Client spouse legal information

export const GET_CLIENT_SPOUSE_LEGAL_DATA = gql`
  query getClientSpouseLegalData($id: ID!) {
    client(id: $id) {
      id
      spouse {
        id
        user {
          id
          documentType {
            id
          }
          documentNumber
        }
        alternativeDocumentType
        domiciled
        countryAddress {
          id
        }
        usPerson
        nationalitySet {
          edges {
            node {
              id
              nationality
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_CLIENT_SPOUSE_LEGAL_DATA = gql`
  mutation updateClientSpouseData($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      client {
        id
        user {
          id
          documentType {
            id
          }
          documentNumber
        }
        alternativeDocumentType
        domiciled
        countryAddress {
          id
        }
        usPerson
        nationalitySet {
          edges {
            node {
              id
              nationality
            }
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

// Client relative information

export const GET_CLIENT_RELATIVE = gql`
  query getClientRelativeInformation($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        firstName
        lastName
        maternalSurname
        documentNumber
        documentType {
          id
          name
        }
      }
      alternativeDocumentType
      relatives {
        edges {
          node {
            id
            fullName
            relationship
            typeRelation
            birthday
            address
            fullAddress {
              id
              country {
                id
                name
              }
              county {
                id
                name
              }
              city {
                id
                name
              }
              district {
                id
                name
              }
              detail
              zipCode
            }
            occupation
            maritalStatus {
              id
            }
            documentType {
              id
            }
            alternativeDocumentType
            documentNumber
            publicFunctions {
              edges {
                node {
                  id
                  entity
                  lastRole
                  timeRole
                }
              }
            }
            participations {
              edges {
                node {
                  id
                  businessName
                  ruc
                  participationPercentage
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_CLIENT_RELATIVE = gql`
  mutation UpdateClientRelative($id: ID!, $input: RelativeInput!) {
    updateRelative(id: $id, input: $input) {
      relative {
        id
        relationship
        typeRelation
        fullName
      }
      errors {
        field
        message
      }
    }
  }
`;

export const CREATE_CLIENT_RELATIVE = gql`
  mutation CreateClientRelative($input: RelativeInput!) {
    createRelative(input: $input) {
      relative {
        id
        relationship
        typeRelation
        fullName
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_CLIENT_RELATIVE = gql`
  mutation DeleteClientRelative($id: ID!) {
    deleteRelative(id: $id) {
      errors {
        field
        message
      }
    }
  }
`;

// Client Relative Participation

export const CREATE_RELATIVE_PARTICIPATION = gql`
  mutation CreateRelativeParticipationMutation(
    $input: RelativeParticipationInput!
  ) {
    createRelativeParticipation(input: $input) {
      errors {
        field
        message
      }
      participation {
        id
        businessName
        ruc
        participationPercentage
      }
    }
  }
`;

export const UPDATE_RELATIVE_PARTICIPATION = gql`
  mutation UpdateRelativeParticipation(
    $id: ID!
    $input: RelativeParticipationInput!
  ) {
    updateRelativeParticipation(id: $id, input: $input) {
      errors {
        field
        message
      }
      participation {
        id
        businessName
        ruc
        participationPercentage
      }
    }
  }
`;

export const DELETE_RELATIVE_PARTICIPATION = gql`
  mutation DeleteRelativeParticipation($id: ID!) {
    deleteRelativeParticipation(id: $id) {
      errors {
        field
        message
      }
      participation {
        id
      }
    }
  }
`;

// Relative Public Functions

export const CREATE_RELATIVE_PUBLIC_FUNCTIONS = gql`
  mutation CreateRelativePublicFunctionMutation(
    $input: RelativePublicFunctionInput!
  ) {
    createRelativePublicFunction(input: $input) {
      publicFunction {
        id
        entity
        lastRole
        timeRole
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_RELATIVE_PUBLIC_FUNCTIONS = gql`
  mutation UpdateRelativePublicFunctionMutation(
    $id: ID!
    $input: RelativePublicFunctionInput!
  ) {
    updateRelativePublicFunction(id: $id, input: $input) {
      publicFunction {
        id
        entity
        lastRole
        timeRole
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_RELATIVE_PUBLIC_FUNCTION = gql`
  mutation DeleteRelativePublicFunction($id: ID!) {
    deleteRelativePublicFunction(id: $id) {
      errors {
        field
        message
      }
      publicFunction {
        id
      }
    }
  }
`;

export const GET_CLIENT_SPOUSE_DATA = gql`
  query getClientSpouseData($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        firstName
        lastName
        maternalSurname
        documentNumber
        documentType {
          id
          name
        }
      }
      alternativeDocumentType
      spouse {
        id
      }
      spouseParticipations {
        edges {
          node {
            id
            businessName
            ruc
            participationPercentage
          }
        }
      }
      spousePublicFunctions {
        edges {
          node {
            id
            entity
            lastRole
            timeRole
          }
        }
      }
    }
  }
`;

export const GET_SPOUSE_PERSONAL_DATA = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        username
      }
      spouse {
        id
        user {
          id
          username
          firstName
          lastName
          maternalSurname
          email
          isEmailAutogenerated
        }
        sex
        birthday
        birthplace {
          id
          country {
            id
            name
          }
          county {
            id
            name
          }
          city {
            id
            name
          }
          district {
            id
            name
          }
        }
        address {
          id
          country {
            id
            name
          }
          county {
            id
            name
          }
          city {
            id
            name
          }
          district {
            id
            name
          }
          detail
        }
        phone
        cellphone
      }
    }
  }
`;

export const GET_SPOUSE_DATA_NOT_VINCULATED = gql`
  query getClient($username: String) {
    filterClients(
      user_Username_Icontains: $username
      user_IsEmailAutogenerated: true
    ) {
      edges {
        node {
          id
          user {
            id
            username
            firstName
            lastName
            maternalSurname
            email
            isEmailAutogenerated
          }
          sex
          birthday
          birthplace {
            id
            country {
              id
              name
            }
            county {
              id
              name
            }
            city {
              id
              name
            }
            district {
              id
              name
            }
          }
          address {
            id
            country {
              id
              name
            }
            county {
              id
              name
            }
            city {
              id
              name
            }
            district {
              id
              name
            }
            detail
          }
          phone
          cellphone
        }
      }
    }
  }
`;

export const GET_ASSIGNEE_PERSONAL_DATA = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      assignee {
        id
        user {
          id
          username
          firstName
          lastName
          maternalSurname
          email
          isEmailAutogenerated
        }
        sex
        birthday
        birthplace {
          id
          country {
            id
            name
          }
          county {
            id
            name
          }
          city {
            id
            name
          }
          district {
            id
            name
          }
        }
        address {
          id
          country {
            id
            name
          }
          county {
            id
            name
          }
          city {
            id
            name
          }
          district {
            id
            name
          }
          detail
        }
        phone
        cellphone
      }
    }
  }
`;

// Client personal data

export const GET_CLIENT_PERSONAL_DATA = gql`
  query getClientPersonalData($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        username
        firstName
        lastName
        maternalSurname
        email
        documentType {
          id
          name
        }
      }
      enviarComunicacion
      nombreComunicacion
      nombreCanalActual
      nombreCanalOrigen
      correoIntermediario
      canalActual
      canalOrigen
      sex
      birthday
      birthplace {
        id
        country {
          id
          name
        }
        county {
          id
          name
        }
        city {
          id
          name
        }
        district {
          id
          name
        }
      }
      address {
        id
        country {
          id
          name
        }
        county {
          id
          name
        }
        city {
          id
          name
        }
        district {
          id
          name
        }
        detail
        zipCode
      }
      addressCondition
      addressConditionChoice
      phone
      cellphone
      fullnameEmergency
      cellphoneEmergency
      relationEmergency
    }
  }
`;

export const UPDATE_CLIENT_PERSONAL_DATA = gql`
  mutation updateClientPersonalData($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      client {
        id
        user {
          id
          username
          firstName
          lastName
          maternalSurname
          email
        }
        enviarComunicacion
        nombreComunicacion
        nombreCanalActual
        nombreCanalOrigen
        correoIntermediario
        canalActual
        canalOrigen
        sex
        birthday
        birthplace {
          id
          country {
            id
            name
          }
          county {
            id
            name
          }
          city {
            id
            name
          }
          district {
            id
            name
          }
        }
        address {
          id
          country {
            id
            name
          }
          county {
            id
            name
          }
          city {
            id
            name
          }
          district {
            id
            name
          }
          detail
          zipCode
        }
        addressCondition
        addressConditionChoice
        phone
        cellphone
        fullnameEmergency
        cellphoneEmergency
        relationEmergency
      }
      errors {
        field
        message
      }
    }
  }
`;

// Client safi

export const LIST_CLIENT_SHEET_TEXTS = gql`
  query listClientSheetTexts {
    listClientSheetTexts {
      edges {
        node {
          id
          relation
          domiciled
          institutionalInvestor
          pep
          usPerson
          dataProcessingAuthorization
          termsAndConditions
        }
      }
    }
  }
`;

export const GET_SAFI_RELATION_CLIENT = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        username
        firstName
        lastName
      }
      funds {
        edges {
          node {
            id
            businessName
            state
          }
        }
      }
      relations {
        edges {
          node {
            id
            name
          }
        }
      }
      assignee {
        id
      }
      pep
      otherRelation
      otherFund
      institutionalInvestor
      spousePep
      relativePep
      dataProcessingAuthorization
      registerNumber
      seatNumber
      departureRegistrationCounty {
        id
      }
    }
  }
`;

export const GET_SAFI_RELATION_SPOUSE = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        username
        firstName
        lastName
      }
      spouse {
        id
        user {
          id
          username
          firstName
          lastName
        }
        relations {
          edges {
            node {
              id
              name
            }
          }
        }
        pep
        otherRelation
        otherFund
        institutionalInvestor
        spousePep
        relativePep
        dataProcessingAuthorization
      }
    }
  }
`;

export const UPDATE_CLIENT_SAFI_INFORMATION = gql`
  mutation updateClientSafiInformation($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      client {
        id
        user {
          id
          username
          firstName
          lastName
        }
        funds {
          edges {
            node {
              id
              businessName
            }
          }
        }
        relations {
          edges {
            node {
              id
              name
            }
          }
        }
        assignee {
          id
        }
        pep
        otherRelation
        otherFund
        institutionalInvestor
        spousePep
        relativePep
        dataProcessingAuthorization
        registerNumber
        seatNumber
        departureRegistrationCounty {
          id
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const GET_CLIENT_TERMS_AND_CONDITIONS = gql`
  query getClientTermsAndConditions($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        username
        firstName
        lastName
      }
      state
      pdfSheet
      lastSignatureDate
      signedPdfSheet
      signedPdfStatus
    }
  }
`;

export const CREATE_CLIENT_SIGNATURE = gql`
  mutation createClientSignature($input: SignatureCreationInput!) {
    createClientSignature(input: $input) {
      clientSheetSignature {
        id
        client {
          id
        }
        validated
        createdAt
        updatedAt
        key
      }
      errors {
        field
        message
      }
    }
  }
`;

export const CREATE_CLIENT_SIGNATURE_KEYNUA = gql`
  mutation createKeynuaProcess($input: KeynuaProcessInput!) {
    createKeynuaProcess(input: $input) {
      keynuaProcess {
        id
        client {
          id
        }
        token
        state
        createdAt
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const RESEND_CLIENT_SIGNATURE_KEY = gql`
  mutation resendClientSignatureKey($input: ResendSignatureKeyInput!) {
    resendClientSignatureKey(input: $input) {
      clientSheetSignature {
        id
        client {
          id
        }
        validated
        createdAt
        updatedAt
        key
      }
      errors {
        field
        message
      }
    }
  }
`;

export const CONFIRM_CLIENT_SIGNATURE_DS = gql`
  mutation confirmClientSignature($input: SignatureConfirmInput!) {
    confirmClientSignature(input: $input) {
      clientSheetSignature {
        id
        client {
          id
          user {
            id
            frontendPermissions {
              edges {
                node {
                  id
                  slug
                }
              }
            }
          }
        }
        validated
        createdAt
        updatedAt
        key
        dsSigningUrl
      }
      errors {
        field
        message
      }
    }
  }
`;

export const CONFIRM_CLIENT_SIGNATURE = gql`
  mutation confirmClientSignatureKeynua($input: SignatureConfirmInput!) {
    confirmClientSignatureKeynua(input: $input) {
      keynuaProcess {
        id
        client {
          id
        }
        token
        state
        createdAt
        updatedAt
      }
      clientSheetSignature {
        id
        client {
          id
          user {
            id
            frontendPermissions {
              edges {
                node {
                  id
                  slug
                }
              }
            }
          }
        }
        validated
        createdAt
        updatedAt
        key
        dsSigningUrl
      }
    }
  }
`;

// Get Current User

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      id
      firstName
      lastName
      username
      email
      dateJoined
      frontendPermissions {
        edges {
          node {
            displayName
            slug
          }
        }
      }
      clientSet {
        edges {
          node {
            id
            isMain
            profilePhoto
            completitionPercentage
            coreId
            isEmployee
            communicationEmail
            lastSignatureDate
            enviarComunicacion
            nombreComunicacion
            nombreCanalActual
            nombreCanalOrigen
            correoIntermediario
            canalActual
            canalOrigen
            user {
              documentType {
                name
                code
              }
              documentNumber
              firstName
              lastName
              maternalSurname
            }
            clientAssignee {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const LIST_COUNTRIES = gql`
  {
    listCountries {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`;

// Client academic background information

export const GET_CLIENT_ACADEMIC_BACKGROUND = gql`
  query getClientAcademicBackgroundInformation($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        firstName
        lastName
        maternalSurname
        documentNumber
        documentType {
          id
          name
        }
      }
      alternativeDocumentType
      academicBackgrounds {
        edges {
          node {
            id
            educationalInstitution
            degree
            startDate
            endDate
            educationLevel {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_CLIENT_ACADEMIC_BACKGROUND = gql`
  mutation UpdateClientAcademicBackground(
    $id: ID!
    $input: AcademicBackgroundInput!
  ) {
    updateAcademicBackground(id: $id, input: $input) {
      academicBackground {
        id
        educationalInstitution
        degree
        startDate
        endDate
        educationLevel {
          id
          name
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const CREATE_CLIENT_ACADEMIC_BACKGROUND = gql`
  mutation CreateClientRelative($input: AcademicBackgroundInput!) {
    createAcademicBackground(input: $input) {
      academicBackground {
        id
        educationalInstitution
        degree
        startDate
        endDate
        educationLevel {
          id
          name
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_CLIENT_ACADEMIC_BACKGROUND = gql`
  mutation DeleteClientRelative($id: ID!) {
    deleteAcademicBackground(id: $id) {
      errors {
        field
        message
      }
    }
  }
`;

// Client property information

export const GET_CLIENT_PROPERTY = gql`
  query getClientPropertyInformation($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        firstName
        lastName
        maternalSurname
        documentNumber
        documentType {
          id
          name
        }
      }
      alternativeDocumentType
      properties {
        edges {
          node {
            id
            propertyType
            address
            registrationNumber
            approximateValue
            condition
            conditionChoice
            detail
            licensePlate
            carYear
            entity
            razonSocial
            ruc
            startDate
            endDate
            esDirecta
            participation
          }
        }
      }
    }
  }
`;

export const UPDATE_CLIENT_PROPERTY = gql`
  mutation UpdateClientProperty($id: ID!, $input: PropertyInput!) {
    updateProperty(id: $id, input: $input) {
      property {
        id
        propertyType
        address
        registrationNumber
        approximateValue
        condition
        conditionChoice
        detail
        licensePlate
        carYear
        entity
        razonSocial
        ruc
        startDate
        endDate
        esDirecta
        participation
      }
      errors {
        field
        message
      }
    }
  }
`;

export const CREATE_CLIENT_PROPERTY = gql`
  mutation CreateClientRelative($input: PropertyInput!) {
    createProperty(input: $input) {
      property {
        id
        propertyType
        address
        registrationNumber
        approximateValue
        condition
        conditionChoice
        detail
        licensePlate
        carYear
        entity
        razonSocial
        ruc
        startDate
        endDate
        esDirecta
        participation
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_CLIENT_PROPERTY = gql`
  mutation DeleteClientRelative($id: ID!) {
    deleteProperty(id: $id) {
      errors {
        field
        message
      }
    }
  }
`;

export const LIST_NEWS = gql`
  {
    listNews {
      edges {
        node {
          id
          newsImage
          state
          newsType
          title
          subtitle
          description
          hasButton
          buttonTitle
          hasLink
          link
        }
      }
    }
  }
`;
