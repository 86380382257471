import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  card: {
    display: 'flex',
    height: '100%',
    background: 'transparent',
    borderRadius: '10px',
    width: '100%',
    
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  img: {
    display: 'block',
    width: '100%',
    minHeight: '20vh',
    maxHeight: '40%',
    objectFit: 'fill',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      // minHeight: '15vh',
      // maxHeight: '30%',
    },
  },
  sliderContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    margin: 'auto',
    overflow: 'hidden',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    transition: 'transform 0.5s ease-in-out',
    justifyContent: 'start',
    height: '100%',
    padding: '10px',
  },
  arrowContainer: {
    position: 'absolute',
    bottom: '20px',
    right: '10px',
    display: 'flex',
    alignItems: 'center',
    height: '76%',
  },
  arrowButton: {
    backgroundColor: 'transparent',
    border: '1px solid #ccc',
    padding: '5px 10px',
    borderRadius: '50%',
    margin: '0 5px',
    cursor: 'pointer',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s',
    color: '#ccc',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: '#000',
    },
  },
  active: {
    opacity: 1,
    transition: 'opacity 0.5s ease-in-out',
  },
  inactive: {
    opacity: 0,
    transition: 'opacity 0.5s ease-in-out',
  },
}));
