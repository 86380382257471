import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';
import { removeClient } from 'src/store/sharedStore/actions';
import { delAuthUser } from 'src/utils/storage';
import getClientMode from '../../../../store/utils-store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from './svg/editIcon';
import ProfileIcon from './svg/profileIcon';
import { REACT_APP_SERVER_LINK } from 'src/utils/constants';
import ArrowDwnIcon from './svg/arrowDwn';
import { Menu, MenuItem } from '@material-ui/core';
import { setModeClient } from 'src/store/utils-store/actions';
import ModeIcon from './svg/modeIcon';
import NotificationsIcon from './svg/notificationsIcon';
import PopUpNotifications from './pop-up-notification';

function ProfileTopBar() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState('');
  //   const [isHovered, setIsHovered] = useState(false);
  const modeClient = useSelector(getClientMode());
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseUser = () => {
    setAnchorEl(null);
  };
  const handleProfileUser = () => {
    history.push('/cliente/perfil');
    handleCloseUser();
  };
  const handleLogOut = () => {
    delAuthUser();
    dispatch(removeClient());
    localStorage.clear();
    history.push('/');
  };
  const handleClickUser = event => {
    setAnchorEl(event.currentTarget);
  };

  const { loading, data: clients } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (clients)
      setProfilePhoto(
        clients.currentUser?.clientSet.edges[0].node.profilePhoto,
      );
  }, [clients]);

  const sizeIcon = ['33', '31'];
  const welcomeStyle = modeClient ? classes.welcomeDark : classes.welcome;
  const nameStyle = modeClient ? classes.nameDark : classes.name;
  const [mode, setMode] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handlerMode = state => {
    setMode(state);
    localStorage.setItem('mode', state);
    dispatch(setModeClient(state));
  };
  const colorStyle = modeClient ? '#FFF' : '#000';
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [clients]);
  return (
    <>
      {windowWidth > 768 && (
        <div className={classes.main}>
          <div className={classes.profile}>
            <div className={classes.profileDiv}>
              <div className={classes.container}>
                <div
                  className={classes.itemProfile}
                  role="button"
                  tabIndex={0}
                  onClick={() => handlerMode(!mode)}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handlerMode(!mode);
                    }
                  }}
                >
                  <ModeIcon bg={modeClient ? '#FFF' : '#000'} />
                </div>
                <div
                  style={{
                    borderRight: `solid 1px ${colorStyle}`,
                    cursor: 'pointer',
                    alignItems: 'center',
                    height: '20px',
                  }}
                ></div>

                <div>
                  <div
                    onClick={() => setOpen(!open)}
                    className={classes.itemProfile}
                  >
                    <NotificationsIcon bg={modeClient ? '#FFF' : '#000'} />
                    {open && (
                      <PopUpNotifications
                        open={open}
                        handleClose={() => setOpen(!open)}
                        anchorEl={null}
                      />
                    )}
                  </div>
                </div>
              </div>

              {profilePhoto ? (
                <div role="button" tabIndex={0} className={classes.editCover}>
                  <img
                    alt="logo"
                    style={{
                      minWidth: `${sizeIcon[0]}px`,
                      minHeight: `${sizeIcon[0]}px`,
                      maxWidth: `${sizeIcon[0]}px`,
                      maxHeight: `${sizeIcon[0]}px`,
                      borderRadius: '50%',
                      border: 'solid 2px #000',
                    }}
                    src={`${REACT_APP_SERVER_LINK}/media/${profilePhoto}`}
                  />
                </div>
              ) : (
                <div role="button" tabIndex={0} className={classes.editCover}>
                  <ProfileIcon
                    w={sizeIcon[0]}
                    h={sizeIcon[1]}
                    bg={modeClient ? '#A6C1D3' : '#000'}
                  />
                </div>
              )}
              <div className={classes.welcomeDiv}>
                <div style={{ display: 'flex', gap: '4px' }}>
                  <p className={welcomeStyle}>Hola,</p>
                  <p className={nameStyle}>
                    {clients && `${clients.currentUser.firstName}`}
                  </p>
                </div>
                <div role="button" tabIndex={0} onClick={handleClickUser}>
                  <ArrowDwnIcon bg={modeClient ? '#A6C1D3' : '#000'} />
                </div>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseUser}
                >
                  <MenuItem
                    onClick={handleProfileUser}
                    id="cambio_contrasena_menu"
                  >
                    Cambio de contraseña
                  </MenuItem>
                  <MenuItem onClick={handleLogOut} id="salir_menu">
                    Salir
                  </MenuItem>
                </Menu>

                {/* {windowWidth < 768 && <NotificationsIcon />} */}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* windowWidth <= 768 && */}
      {windowWidth <= 980 && (
        <div className={classes.main}>
          <div className={classes.profile}>
            <div className={classes.profileDiv}>
              <div className={classes.containerTopBar}>
                <div className={classes.container}>
                  <div
                    className={classes.itemProfile}
                    role="button"
                    tabIndex={0}
                    onClick={() => handlerMode(!mode)}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handlerMode(!mode);
                      }
                    }}
                  >
                    <ModeIcon bg={modeClient ? '#FFF' : '#000'} />
                  </div>
                  <div
                    style={{
                      borderRight: `solid 1px ${colorStyle}`,
                      cursor: 'pointer',
                      alignItems: 'center',
                      height: '20px',
                    }}
                  ></div>

                  <div>
                    <div
                      onClick={() => setOpen(!open)}
                      className={classes.divNotificaciones}
                    >
                      <NotificationsIcon bg={modeClient ? '#FFF' : '#000'} />
                      {open && (
                        <PopUpNotifications
                          open={open}
                          handleClose={() => setOpen(!open)}
                          anchorEl={null}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  {profilePhoto ? (
                    <>
                      <div
                        onClick={handleClickUser}
                        role="button"
                        tabIndex={0}
                        className={classes.editCover}
                      >
                        <img
                          alt="logo"
                          style={{
                            minWidth: `${sizeIcon[0]}px`,
                            minHeight: `${sizeIcon[0]}px`,
                            maxWidth: `${sizeIcon[0]}px`,
                            maxHeight: `${sizeIcon[0]}px`,
                            borderRadius: '50%',
                            border: 'solid 2px #000',
                          }}
                          src={`${REACT_APP_SERVER_LINK}/media/${profilePhoto}`}
                        />
                      </div>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseUser}
                      >
                        <MenuItem
                          onClick={handleProfileUser}
                          id="cambio_contrasena_menu"
                        >
                          Cambio de contraseña
                        </MenuItem>
                        <MenuItem onClick={handleLogOut} id="salir_menu">
                          Salir
                        </MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <>
                      <div
                        onClick={handleClickUser}
                        role="button"
                        tabIndex={0}
                        className={classes.editCover}
                      >
                        <ProfileIcon
                          w={sizeIcon[0]}
                          h={sizeIcon[1]}
                          bg={modeClient ? '#A6C1D3' : '#000'}
                        />
                      </div>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseUser}
                      >
                        <MenuItem
                          onClick={handleProfileUser}
                          id="cambio_contrasena_menu"
                        >
                          Cambio de contraseña
                        </MenuItem>
                        <MenuItem onClick={handleLogOut} id="salir_menu">
                          Salir
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfileTopBar;
