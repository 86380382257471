import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  card: {
    display: 'flex',
    minHeight: '20vh',
    maxHeight: '40%',
    background: 'linear-gradient(180deg, rgba(9, 9, 9, 0.82) 0%, #090909 100%)',
    borderRadius: '10px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  sliderContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: '800px',
    height: '100%',
    margin: 'auto',
    overflow: 'hidden',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    transition: 'transform 0.5s ease-in-out',
    justifyContent: 'start',
    height: '100%',
    padding: '10px',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    paddingBottom: '8px', // pb-2
    justifyContent: 'center',
    height: '100%',
    padding: '10px',
    gap: '4px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px', // gap-1
  },
  lightText: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '300', // font-light
    fontSize: '1.525rem', // text-lg
    color: '#FFF',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  boldText: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '700', // font-bold
    fontSize: '1.525rem', // text-lg
    color: '#FFF',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  subTitle: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '700', // font-bold
    fontSize: '0.75rem', // text-xs
    color: '#F97A00',
    margin: 0,
  },
  description: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '500', // font-bold
    fontSize: '0.75rem', // text-xs
    color: '#FFF',
    margin: 0,
  },
  arrowContainer: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  arrowButton: {
    backgroundColor: 'transparent',
    border: '1px solid #ccc',
    padding: '5px 10px',
    borderRadius: '50%',
    margin: '0 5px',
    cursor: 'pointer',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: '#000',
    },
  },
  active: {
    opacity: 1,
    transition: 'opacity 0.5s ease-in-out',
  },
  inactive: {
    opacity: 0,
    transition: 'opacity 0.5s ease-in-out',
  },
  divHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1px',
  },
  divTitles: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
  },

  buttom: {
    border: '1px solid #F97A00',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    background: 'transparent',
    color: '#F97A00',
    fontWeight: 600,
    fontSize: '0.75rem',
    borderRadius: '8px',
  },
  buttonDiv: {
    paddingTop: '5px',
  },
}));
