import { useQuery } from '@apollo/react-hooks';

import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { LIST_NEWS } from '@queries/Client';
import { REACT_APP_SERVER_LINK } from 'src/utils/constants';

export default function EventsCard() {
  const classes = useStyles();
  const { loading, data: list } = useQuery(LIST_NEWS);
  const apiData = list?.listNews?.edges;
  const [currentIndex, setCurrentIndex] = useState(0);
  const data = apiData?.filter(
    item => item?.node?.newsType === 'I' && item?.node?.state === true,
  );
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(prevIndex =>
        prevIndex === data?.length - 1 ? 0 : prevIndex + 1,
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, [data?.length]);

  const handleNext = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === data?.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const handlePrev = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? data?.length - 1 : prevIndex - 1,
    );
  };



  return (
    <>
      {data?.length > 0 && (
        <div className={classes.card}>
          <div className={classes.sliderContainer}>
            <div className={classes.cardContainer}>
              {data.map(({ node }, index) => (
                <div
                  key={node.id}
                  className={`${
                    index === currentIndex ? classes.active : classes.inactive
                  }`}
                  style={{ display: index === currentIndex ? 'block' : 'none' }}
                >
                  <img
                    className={classes.img}
                    // src={node.newsImage}
                    // src={`${REACT_APP_SERVER_LINK}/media/${node.newsImage}`} 
                    src={`https://dev-backend-intranet.corepartners.com.pe/media/${node.newsImage}`}
                    alt={node.title}
                  />
                </div>
              ))}
            </div>
            <div className={classes.arrowContainer}>
              <button className={classes.arrowButton} onClick={handlePrev}>
                {'<'}
              </button>
              <button className={classes.arrowButton} onClick={handleNext}>
                {'>'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
