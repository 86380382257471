import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '60%',
    gap: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    alignItems: 'start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  newsDiv: {
    display: 'flex',
    maxHeight: 'fit-content',
    height: '100%',
    justifyContent: 'center',
    borderRadius: '10px',
    width: '52%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  simpleNoteDiv: {
    display: 'flex',
    maxHeight: 'fit-content',
    height: '100%',
    justifyContent: 'center',
    borderRadius: '10px',
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
